import React, { useState, useRef, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  TextField,
  MenuItem,
  Divider,
  Stack,
  InputAdornment,
  Alert,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {
  createReccomendedServices,
  submitTechnicalApproval,
  uploadPdf,
} from '../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import ModalTableHead from './components/table_head'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { APP_FONT } from '../../../../constants/app_font'
import { Offer } from '../../../../models/offer_new'
import { holidays, isSameDay } from '../../../../utils/holidays'
import DeleteIcon from '../../../../assets/images/delete.svg'
import FilePreview from './components/file_preview'
import Autocomplete from '@mui/material/Autocomplete'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg'
import { ReactComponent as PdfDownloadIcon } from '../../../../assets/images/pdf_download.svg'
import { formatPrice } from '../../../../components/helper/helper'
import { ReactComponent as Close } from '../../../../assets/images/close.svg'

const MAX_FILE_SIZE = 25 * 1024 * 1024
interface ServiceChild {
  id: string
  name: string
}

interface Service {
  id: string
  name: string
  children: ServiceChild[]
}

interface RecommendedService {
  id: string
  name: string
  type: string
  types: any[]
  price: number
  serviceType: string
}

interface DiagnosticUploadModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  onClose: () => void
  onSubmit: () => void
  offer: Offer
  services: Service[]
}

interface ServicePrices {
  [serviceId: string]: string
}

const DiagnosticUploadModal: React.FC<DiagnosticUploadModalProps> = ({
  setOpen,
  open,
  onClose,
  onSubmit,
  offer,
  services,
}) => {
  const dispatch = useDispatch()
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'>(
    'IDLE'
  )
  const navigate = useNavigate()
  const [recServices, setRecServices] = useState<RecommendedService[]>([])
  const [pickupAfter, setPickupAfter] = useState<Date | null>(
    offer.pick_up_after ? new Date(offer.pick_up_after) : null
  )
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [diagnosticFeedbackFile, setDiagnosticFeedbackFile] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showAdditionalServices, setShowAdditionalServices] = useState(false)
  const [options, setOptions] = useState<Service[]>(services)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchServices = async (query: string) => {
    if (query) {
      setLoading(true)
      setOptions([])
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NEST_JS_API}service?level=2&search=${query}`
        )
        const data = await response.json()

        const filteredOptions = data.items.filter(
          (item: any) =>
            item.name.toLowerCase().includes(query.toLowerCase()) && item.parent_id !== null
        )

        setOptions(filteredOptions)
      } catch (error) {
        console.error('Failed to fetch services:', error)
      } finally {
        setLoading(false)
      }
    } else {
      setOptions(services)
    }
  }

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    fetchServices(newInputValue)
  }

  const dropOffTime = new Date(offer.pick_up_after)
  const today = new Date()
  let minDate = new Date(dropOffTime.getFullYear(), dropOffTime.getMonth(), dropOffTime.getDate())

  let maxDate = new Date(minDate)
  maxDate.setDate(maxDate.getDate() + 1)

  let minTimeToday = new Date()
  minTimeToday.setHours(dropOffTime.getHours(), dropOffTime.getMinutes() + 60, 0)
  const minTimeNextDays = new Date(new Date().setHours(8, 0, 0))

  const maxTime = new Date()
  maxTime.setHours(17, 0, 0)

  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }

  const handleServicePriceChange = (
    serviceId: string,
    serviceType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setServicePrices((prevPrices) => ({
      ...prevPrices,
      [`${serviceId}_${serviceType}`]: formattedNumber,
    }))
  }

  useEffect(() => {
    const newTotal = calculateSubTotalPrice()
    const formattedTotal = newTotal.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices, recServices])

  const calculateSubTotalPrice = (): number => {
    return Object.values(servicePrices).reduce((total, priceStr) => {
      const cleanPriceStr = priceStr.replace(/[^\d.]/g, '')
      const priceNumber = parseFloat(cleanPriceStr)

      if (!isNaN(priceNumber)) {
        const roundedPrice = Math.round(priceNumber * 100) / 100
        return total + roundedPrice
      }
      return total
    }, 0)
  }

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees?.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax?.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice()

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [recServices, servicePrices, shopSupplyFees, fee, tax])

  useEffect(() => {
    const initialRecommendedServices = offer?.services
      .filter((service) => !service.is_approved)
      .map((service) => {
        const serviceId = service.service.parent.id || service.service.id
        const matchingService = services.find(
          (s) => s.id === service.service.parent.id || s.id === service.service.id
        )
        if (matchingService) {
          const formattedNumber = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
            .format(service.price)
            .slice(1)

          setServicePrices((prevPrices) => ({
            ...prevPrices,
            [`${serviceId}_${service.service.id}`]: formattedNumber,
          }))

          return {
            id: service.service.parent.id,
            name: matchingService.name,
            type: service.service.id,
            types: matchingService.children,
            price: service.price,
            serviceType: service.service.type,
          }
        }
        return null
      })
      .filter((service) => service !== null) as RecommendedService[]

    setRecServices(initialRecommendedServices)
  }, [offer.services, services])

  useEffect(() => {
    if (offer?.files) {
      const feedbackFile = offer.files.find((file) => file.type === 'DIAGNOSTIC_FEEDBACK')
      setDiagnosticFeedbackFile(feedbackFile || null)
      setShowAdditionalServices(true)
      setIsLoading(false)
    }
    setIsLoading(false)
  }, [offer])

  const handleFileSelect = (selectedFile: File) => {
    if (selectedFile.size > MAX_FILE_SIZE) {
      setAlertOpen(true)
      setAlertText('File is too large. Maximum size is 10MB.')
      return
    }
    setFile(selectedFile)
    setUploadStatus('IDLE')
  }

  const handleUpload = async () => {
    if (!file || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatus('ERROR')
      return
    }
    setUploadStatus('UPLOADING')
    const formData = new FormData()
    formData.append('file', file)

    try {
      dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'ESTIMATE', formData }))
        .then(unwrapResult)
        .then((data: any) => {
          setUploadStatus('SUCCESS')
          handleSubmit()
        })
    } catch (error: any) {
      console.error('Error uploading file:', error)
      setUploadStatus('ERROR')
      setAlertText(error ?? 'ERROR on file upload')
    }
  }

  const uploadFeedback = () => {
    switch (uploadStatus) {
      case 'UPLOADING':
        return <Typography color="primary">Uploading...</Typography>
      case 'SUCCESS':
        return <Typography color="green">Upload successful!</Typography>
      case 'ERROR':
        return <Typography color="error">Upload failed. Try again.</Typography>
      default:
        return null
    }
  }

  const renderDroppableArea = () => (
    <div
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => {
        event.preventDefault()
        if (event.dataTransfer.files[0]) {
          handleFileSelect(event.dataTransfer.files[0])
        }
      }}
      style={{
        border: '2px dashed #C4C4C4',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '16px',
        transition: 'background-color 0.3s',
        backgroundColor: '#FAFAFA',
      }}
    >
      <CloudUploadIcon style={{ fontSize: '50px', color: '#C4C4C4' }} />
      <Typography>
        <Button
          variant="text"
          component="span"
          style={{ textTransform: 'none', padding: 0 }}
          color="warning"
          onClick={handleUploadClick}
        >
          Click to upload
        </Button>{' '}
        or drag and drop
      </Typography>
      <Typography variant="caption">PDF, PNG, JPG, or JPEG (max. 25 MB)</Typography>
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="file-input"
        type="file"
        accept="application/pdf, image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files?.[0]) {
            handleFileSelect(e.target.files[0])
          }
        }}
      />
      {file && (
        <>
          <Typography variant="body2">{`Selected file: ${file.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
    </div>
  )

  const handleServiceChange = (index: number, field: keyof RecommendedService, value: string) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          if (field === 'id') {
            const selectedService = services.find((s) => s.id === value)
            return {
              ...service,
              [field]: value,
              name: selectedService ? selectedService.name : '',
              type: '',
              types: selectedService ? selectedService.children : [],
            }
          } else {
            return { ...service, [field]: value }
          }
        }
        return service
      })
    )
  }

  const handleAddService = () => {
    const newService: RecommendedService = {
      id: `service_${recServices.length}`,
      name: '',
      type: '',
      types: [],
      price: 0,
      serviceType: '',
    }
    setRecServices((prevServices) => [...prevServices, newService])
  }

  const handleRemoveService = (serviceId: string, serviceType: string) => {
    setRecServices((prevServices) =>
      prevServices.filter(
        (service) => `${service.id}_${service.type}` !== `${serviceId}_${serviceType}`
      )
    )
    setServicePrices((prevPrices) => {
      const updatedPrices = { ...prevPrices }
      delete updatedPrices[`${serviceId}_${serviceType}`]
      return updatedPrices
    })
  }

  const handleSubmit = async () => {
    const filteredServices = recServices.filter((service) => service.serviceType !== 'DIAGNOSIS')

    if (
      filteredServices.some(
        (service) => !service.name || !servicePrices[`${service.id}_${service.type}`]
      )
    ) {
      setAlertOpen(true)
      return
    }

    setAlertOpen(false)

    const formattedServices = filteredServices.map((service) => ({
      service_id: service.type ? service.type : service.id,
      price:
        parseFloat(
          (servicePrices[`${service.id}_${service.type}`] ?? '0').replace(/[^0-9.-]+/g, '')
        ) || 0,
    }))

    const payload = {
      supply_fee: parseFloat(shopSupplyFees?.replace(/[^0-9.-]+/g, '')) || 0,
      tax: parseFloat(tax?.replace(/[^0-9.-]+/g, '')) || 0,
      services: formattedServices,
    }

    try {
      const diagnosticFile = offer.files.find((file) => file.type === 'ESTIMATE')
      if (offer.files.length > 0 && diagnosticFile && diagnosticFile.is_approved === true) {
        dispatch<any>(createReccomendedServices({ offerId: offer.id, body: payload }))
          .then(unwrapResult)
          .then((data: any) => {
            onSubmit()
            navigate(0)
          })
      } else {
        dispatch<any>(submitTechnicalApproval({ offerId: offer.id, body: payload }))
          .then(unwrapResult)
          .then((data: any) => {
            onSubmit()
            navigate(0)
          })
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      setUploadStatus('ERROR')
    }
  }

  const renderServiceBlocks = () => (
    <>
      {recServices.map((service, index) => (
        <React.Fragment key={`${service.id}_${service.type}_${index}`}>
          <Grid container spacing={2} alignItems="center" mb={2}>
            {service.serviceType === 'DIAGNOSIS' ? (
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  value={'Full general diagnostic'}
                  InputProps={{
                    readOnly: true,
                    style: { background: '#fff' },
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={5}>
                <Autocomplete
                  options={options}
                  value={
                    services.find(
                      (service: any) =>
                        service.id === recServices[index].id ||
                        service.id === recServices[index].type
                    ) || undefined
                  }
                  onChange={(event, newValue: any) => {
                    handleServiceChange(index, 'id', newValue?.id || '')
                  }}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option: any) => option.name}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Services"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  blurOnSelect={true}
                />
              </Grid>
            )}

            {service.serviceType !== 'DIAGNOSIS' && (
              <Grid item xs={3}>
                <TextField
                  select
                  fullWidth
                  label="Type"
                  value={service.type}
                  onChange={(e) => handleServiceChange(index, 'type', e.target.value)}
                  disabled={!service.name || !service.types.length}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: '50vh',
                        },
                      },
                    },
                  }}
                >
                  {service.types.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Price"
                value={servicePrices[`${service.id}_${service.type}`] || ''}
                onChange={(e: any) => handleServicePriceChange(service.id, service.type, e)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                  readOnly: service.serviceType === 'DIAGNOSIS',
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px' },
                  readOnly: service.serviceType === 'DIAGNOSIS',
                }}
              />
            </Grid>

            {service.serviceType !== 'DIAGNOSIS' && (
              <Grid item xs={1}>
                <IconButton
                  onClick={() => handleRemoveService(service.id, service.type)}
                  size="large"
                >
                  <img src={DeleteIcon} alt="Delete" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      ))}
    </>
  )

  const renderSummary = () => {
    if (recServices.length === 0) {
      return null
    }

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={2}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                marginRight: '8px',
              }}
            >
              Subtotal:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#DB5D08',
              }}
            >
              {fee && formatPrice(fee)}
            </Typography>
          </Box>
          <Divider sx={{ marginY: '16px !important' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
              }}
            >
              Shop supply fees:
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontFamily: APP_FONT, color: 'text.secondary', pr: 2 }}
            >
              *for recommended services only
            </Typography>
          </Stack>
          <TextField
            value={shopSupplyFees}
            onChange={handleFeeChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                pr: 2,
              }}
            >
              Tax:
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontFamily: APP_FONT, color: 'text.secondary', pr: 2 }}
            >
              *for recommended services only
            </Typography>
          </Stack>
          <TextField
            value={tax}
            onChange={handleTaxChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>

        <Box display="flex" gap={4}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mb: 2,
            }}
          >
            Total:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#DB5D08',
            }}
          >
            {totalFee && formatPrice(totalFee)}
          </Typography>
        </Box>
        {offer.services.length > 1 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#393A3D',
                  mr: 2,
                }}
              >
                Initially requested services (including supply fees and taxes):
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#393A3D',
                }}
              >
                {formatPrice(offer.total_fee ?? '0.00')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#393A3D',
                  mr: 2,
                }}
              >
                Recommended services (including supply fees and taxes):
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#393A3D',
                }}
              >
                {totalFee && formatPrice(totalFee)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#393A3D',
                  mr: 2,
                }}
              >
                Total:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#DB5D08',
                }}
              >
                {formatPrice(
                  parseFloat(offer.total_fee) + parseFloat(totalFee ?? '0.00') ?? '0.00'
                )}
              </Typography>
            </Box>
          </>
        )}
        {/* <Stack sx={{ display: 'flex' }}>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: APP_FONT,
              fontWeight: '700',
              textAlign: 'left',
              mb: 1,
              pl: 1,
            }}
          >
            Pick up time:
          </Typography>
          <DatePicker
            selected={pickupAfter}
            onChange={handleTempDate}
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            placeholderText="mm/dd/yyyy --:-- --"
            className="my-datepicker-input"
            minDate={minDate}
            maxDate={maxDate}
            minTime={pickupAfter && isSameDay(pickupAfter, today) ? minTimeToday : minTimeNextDays}
            maxTime={maxTime}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: APP_FONT,
              fontWeight: '400',
              color: '#393A3D',
              width: 270,
              paddingLeft: 1,
              marginTop: '0px',
              marginBottom: 2,
            }}
          >
            When will the car be ready for pick up?
          </Typography>
        </Stack> */}
      </>
    )
  }

  const renderDiagnosticFeedbackFile = () => {
    if (!diagnosticFeedbackFile) return null

    return (
      <Box
        sx={{
          mt: 2,
          mb: 2,

          p: 2,
          border: 1,
          borderColor: '#FFB38C',
          borderRadius: 'borderRadius',
          bgcolor: 'background.paper',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF6600' }}>
          Carma ASE-Certified Master Technician Feedback
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 2 }}>
          Download below to view feedback, make necessary adjustments to theapproval, and resubmit:
        </Typography>
        <Box
          sx={{
            width: '80%',
            padding: 2,
            boxShadow: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PdfDownloadIcon />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {diagnosticFeedbackFile.name} ({diagnosticFeedbackFile.size})
            </Typography>
          </Box>
          <Button
            color="warning"
            href={diagnosticFeedbackFile.url}
            download
            startIcon={<DownloadIcon />}
            target="_blank"
          />
        </Box>
      </Box>
    )
  }

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  const isDateDisabled = (date: Date) => {
    return holidays.some((holiday) => isSameDay(date, holiday))
  }

  const handleTempDate = (date: Date) => {
    const isHoliday = isDateDisabled(date)
    setAlertOpen(isHoliday)
    setPickupAfter(date)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      {isLoading ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, height: '120vh' }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress
            size={60}
            sx={{
              color: '#FF6600',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-30px',
              marginLeft: '-30px',
            }}
          />
        </Backdrop>
      ) : (
        <>
          <Box>
            <ModalTableHead
              onHide={onClose}
              service_request={{
                slug: offer.consumer_request.slug,
                vehicle: offer.vehicle,
                services: offer.services,
                drop_off_time: offer.drop_off_by,
                is_diagnostic: offer.consumer_request.type === 'DIAGNOSTIC',
              }}
            />
          </Box>
          {alertOpen && <Alert severity="error">{alertText}</Alert>}
          <DialogContent>
            <Grid container spacing={2} sx={{ height: '100%' }}>
              <Grid item xs={12} md={5} sx={{ borderRight: '1px solid #EAECF0', pr: 2 }}>
                {renderDiagnosticFeedbackFile()}
                {offer?.consumer_request.files.length > 0 ? (
                  <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Photos and video by customer
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                      Uploaded by the customer to explain the issue
                    </Typography>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
                      <FilePreview files={offer?.consumer_request.files || []} />
                    </Box>
                  </>
                ) : (
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    No media was uploaded by the customer
                  </Typography>
                )}
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Requested services
                </Typography>
                {offer.services
                  .filter((service) => service.is_approved)
                  .map((service, index) => (
                    <Box key={`${service.id}_${service.type}_${index}`}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1,
                          mt: 1,
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontFamily: APP_FONT,
                              fontWeight: '600',
                              color: '#393A3D',
                              mt: 1,
                            }}
                          >
                            {service.service.parent.parent_id
                              ? service.service.parent.name
                              : service.service.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {service.service.parent.parent_id && service.service.name}
                          </Typography>
                          {service.service.required_data?.includes('PMI') && (
                            <a
                              href="https://joincarma-images.s3.amazonaws.com/PMI+final.pdf"
                              download
                              target="_blank"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: '#FF6600',
                              }}
                              rel="noreferrer"
                            >
                              <Typography
                                fontFamily={APP_FONT}
                                fontSize={16}
                                mr={1}
                                style={{
                                  fontSize: '16px',
                                  fontFamily: APP_FONT,
                                  fontWeight: '600',
                                  color: '#FF6600',
                                }}
                              >
                                PMI Download Link
                              </Typography>
                              <DownloadIcon color="#FF6600" />
                            </a>
                          )}
                        </Stack>
                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontFamily: APP_FONT,
                            fontWeight: '600',
                            color: '#393A3D',
                          }}
                        >
                          {formatPrice(service.price)}
                        </Typography>
                      </Box>
                      {index < offer.services.length - 1 && <Divider />}
                    </Box>
                  ))}
                <Divider sx={{ my: 2 }} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      mr: 2,
                    }}
                  >
                    Shop supply fees:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                    }}
                  >
                    {formatPrice(offer.supply_fee ?? '0.00')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      mr: 2,
                    }}
                  >
                    Tax
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                    }}
                  >
                    {formatPrice(offer.tax ?? '0.00')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      mr: 2,
                    }}
                  >
                    {offer.services.length > 1 ? 'Subtotal:' : 'Total:'}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {formatPrice(offer.total_fee ?? '0.00')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, color: '#FF6600' }}>
                  Diagnostic results:
                </Typography>
                <Grid item xs={12} sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Diagnostic Report
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
                    Includes services required to resolve issues identified during diagnostics
                  </Typography>
                  {renderDroppableArea()}
                  {!showAdditionalServices && (
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '700',
                          textAlign: 'center',
                          mb: 3,
                          mt: 3,
                          color: '#393A3D',
                        }}
                      >
                        Does this vehicle need any other services? If so, use the button below to
                        add them.
                      </Typography>

                      <Stack sx={{ alignItems: 'center' }}>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={() => setShowAdditionalServices(true)}
                          sx={{
                            width: 'fit-content',
                            borderRadius: '20px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            paddingLeft: '32px',
                            paddingRight: '32px',
                          }}
                        >
                          + Recommend additional services
                        </Button>
                      </Stack>
                    </Stack>
                  )}

                  {showAdditionalServices && (
                    <Box sx={{ flexGrow: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: 'bold', mb: 3, mt: 3, color: '#FF6600' }}
                        >
                          Additional recommended services
                        </Typography>

                        <Box>
                          <Button
                            variant="contained"
                            onClick={() => setShowAdditionalServices(false)}
                            sx={{
                              width: 'fit-content',
                              borderRadius: '20px',
                              textTransform: 'none',
                              fontWeight: 'bold',
                              paddingLeft: '32px',
                              paddingRight: '32px',
                              backgroundColor: '#fff',
                              mt: 3,
                              color: '#000',
                              '&:hover': {
                                backgroundColor: '#e0e0e0',
                              },
                              '&:active': {
                                backgroundColor: '#grey',
                                color: '#fff',
                              },
                            }}
                          >
                            <Close />{' '}
                            <Typography sx={{ ml: 2 }}> Remove additional services</Typography>
                          </Button>
                        </Box>
                      </Box>

                      <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
                        List all the services which customer's vehicle needs
                      </Typography>
                      {renderServiceBlocks()}
                      <Button
                        onClick={handleAddService}
                        variant="outlined"
                        color="warning"
                        sx={{ mt: 1, textTransform: 'none' }}
                      >
                        Add service
                      </Button>
                      {renderSummary()}
                    </Box>
                  )}
                </Grid>
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    variant="contained"
                    color="warning"
                    disabled={!file || (uploadStatus !== 'IDLE' && uploadStatus !== 'SUCCESS')}
                    onClick={handleUpload}
                    sx={{
                      width: 'fit-content',
                      borderRadius: '20px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      paddingLeft: '32px',
                      paddingRight: '32px',
                    }}
                  >
                    Submit for technician approval
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default DiagnosticUploadModal
