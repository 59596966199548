import {
  Box,
  Button,
  Modal,
  Alert,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  IconButton,
  AlertTitle,
  TextField,
  InputAdornment,
  Divider,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { ServiceRequest } from '../../../../models/service_request'
import { CreateOffer } from '../../../../models/offer'
import { createOffer } from '../../../../redux/actions/offer'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { APP_FONT } from '../../../../constants/app_font'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'
import { holidays, isSameDay } from '../../../../utils/holidays'
import DatePicker from 'react-datepicker'
import ModalTableHead from './components/table_head'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflowY: 'auto',
}

interface Props {
  setOpen: (arg: boolean) => void
  open: boolean
  onHide: () => void
  service_request: ServiceRequest
  selectedTimeblock: string | undefined
  setSelectedTimeblock: (arg: any) => void
  alert: boolean
  setAlert: (arg: boolean) => void
}

const isWeekday = (date: Date) => {
  const day = date.getDay()
  return day !== 0 && day !== 6
}

export interface ServicePrices {
  [serviceId: string]: string
}

export default function OfferModal({
  setOpen,
  open,
  setAlert,
  alert,
  onHide,
  service_request,
}: Props) {
  const dispatch = useDispatch()
  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [alertText, setAlertText] = useState(
    'Please select a timeblock and offer value for the offer.'
  )
  const navigate = useNavigate()
  const [alertOpen, setAlertOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')

  /////////date and time
  const [pickupAfter, setPickupAfter] = useState<any | null>(() => {
    const initialDate = new Date(service_request.requestedDateTime)
    initialDate.setMinutes(initialDate.getMinutes() + 30)
    return initialDate
  })

  const [isPickupDateValid, setIsPickupDateValid] = useState(false)
  const [minDate, setMinDate] = useState<Date>(new Date(service_request.requestedDateTime))
  const [maxDate, setMaxDate] = useState<Date>(new Date(service_request.requestedDateTime))

  const defaultMaxTime = new Date()
  defaultMaxTime.setHours(23, 30, 0)

  const maxTime = new Date()
  maxTime.setHours(17, 0, 0)

  function calculateMaxDate(date: any) {
    let newMaxDate = new Date(date)
    const classOrder = service_request.vehicle.class?.order
    let additionalDays = classOrder ? (classOrder <= 3 ? 1 : 2) : 1

    if (newMaxDate.getDay() === 5) {
      newMaxDate.setDate(newMaxDate.getDate() + 2 + additionalDays)
    } else if (newMaxDate.getDay() === 6) {
      newMaxDate.setDate(newMaxDate.getDate() + 1 + additionalDays)
    } else {
      newMaxDate.setDate(newMaxDate.getDate() + additionalDays)
    }

    return newMaxDate
  }

  const isDateDisabled = (date: Date) => {
    return holidays.some((holiday) => isSameDay(date, holiday))
  }

  const getMinTime = () => {
    let selectedDateString
    if (!pickupAfter) {
      selectedDateString = service_request.requestedDateTime?.split('T')[0]
    } else {
      selectedDateString = pickupAfter?.toISOString().split('T')[0]
    }

    const defaultDateString = minDate?.toISOString().split('T')[0]

    if (selectedDateString === defaultDateString) {
      const requestedDateTime = new Date(service_request.requestedDateTime)
      return new Date(
        requestedDateTime.setHours(
          requestedDateTime.getHours(),
          requestedDateTime.getMinutes() + 30,
          0,
          0
        )
      )
    } else {
      return new Date(new Date().setHours(8, 0, 0))
    }
  }

  const getMaxTime = () => {
    let selectedDateString
    if (!pickupAfter) {
      selectedDateString = service_request.requestedDateTime?.split('T')[0]
    } else {
      selectedDateString = pickupAfter?.toISOString().split('T')[0]
    }

    const defaultDateString = maxDate?.toISOString().split('T')[0]
    if (selectedDateString === defaultDateString) {
      const requestedDateTime = new Date(service_request.requestedDateTime)
      requestedDateTime.setHours(
        requestedDateTime.getHours() + (service_request.vehicle.class?.order <= 3 ? 24 : 48)
      )
      return requestedDateTime
    } else {
      return new Date(new Date().setHours(17, 0, 0))
    }
  }

  const handleTempDate = (date: Date) => {
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
      const defaultTime = new Date(service_request.requestedDateTime)
      date.setHours(defaultTime.getHours(), defaultTime.getMinutes(), 0)
    }
    const isHoliday = isDateDisabled(date)
    setAlertOpen(isHoliday)
    setPickupAfter(date)
  }

  useEffect(() => {
    const newTempDate = new Date(service_request.requestedDateTime)
    setMinDate(newTempDate)
    const max = calculateMaxDate(minDate)
    setMaxDate(max)
  }, [service_request.requestedDateTime])

  useEffect(() => {
    const pickupDate = new Date(pickupAfter)
    const isPickupDateValid = !isNaN(pickupDate.getTime())
    let isAfterRequestedDateTime = false
    if (isPickupDateValid) {
      isAfterRequestedDateTime = pickupDate > new Date(service_request.requestedDateTime)
    }

    setIsPickupDateValid(isAfterRequestedDateTime)
  }, [pickupAfter, service_request.requestedDateTime])
  ///////////////

  useEffect(() => {
    const initialPrices: ServicePrices = {}
    service_request.services.forEach((service: any) => {
      const price = service.service.type === 'DIAGNOSIS' ? '0.00' : ''
      initialPrices[service.service_id] = price
    })

    setServicePrices(initialPrices)
  }, [service_request])

  const handleOfferSubmission = () => {
    if (
      totalFee === undefined ||
      pickupAfter === undefined ||
      service_request.requestedDateTime === undefined
    ) {
      setAlert(true)
    } else {
      const providerId = localStorage.getItem('branchId')
      if (providerId) {
        setLoading(true)
        const servicesArray = Object.entries(servicePrices).map(([serviceId, price]: any) => ({
          service_id: serviceId,
          price: parseFloat(price.replace(/,/g, '')),
        }))

        const offer: CreateOffer = {
          ...(shopSupplyFees && { supply_fee: parseFloat(shopSupplyFees.replace(/,/g, '')) }),
          ...(tax && { tax: parseFloat(tax.replace(/,/g, '')) }),
          consumer_request_id: service_request.id,
          provider_branch_id: providerId,
          drop_off_by: new Date(service_request.requestedDateTime).toISOString(),
          // pick_up_after: pickupAfter,
          services: servicesArray,
        }

        dispatch<any>(createOffer(offer))
          .then(unwrapResult)
          .then((offer: any) => {
            if (offer) {
              setOpen(false)
              navigate(0)
            }
          })
          .catch((error: any) => {
            setLoading(false)
            setAlert(true)
            setAlertText(error)
            console.log('error')
          })
      }
    }
  }

  const handleServicePriceChange = (
    serviceId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setServicePrices((prevPrices) => ({
      ...prevPrices,
      [serviceId]: formattedNumber,
    }))
  }

  useEffect(() => {
    const total = calculateSubTotalPrice()
    const formattedTotal = total.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices])

  const calculateSubTotalPrice = (): number => {
    return Object.values(servicePrices).reduce((total, priceStr) => {
      const cleanPriceStr = priceStr.replace(/[^\d.]/g, '')
      const priceNumber = parseFloat(cleanPriceStr)

      if (!isNaN(priceNumber)) {
        const roundedPrice = Math.round(priceNumber * 100) / 100
        return total + roundedPrice
      }
      return total
    }, 0)
  }

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice()

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [fee, tax, shopSupplyFees])

  const allPricesSet = (): boolean => {
    return service_request.services.every(
      (service) => servicePrices[service.service_id] && servicePrices[service.service_id] !== ''
    )
  }

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  return (
    <Modal
      open={open}
      onClose={onHide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      sx={{ overflow: 'hidden' }}
    >
      <Box sx={style}>
        {alert && <Alert severity="error">{alertText}</Alert>}
        <ModalTableHead
          onHide={onHide}
          service_request={{
            slug: service_request.slug,
            vehicle: service_request.vehicle,
            services: service_request.services,
            drop_off_time: service_request.requestedDateTime,
            is_diagnostic: service_request.type === 'DIAGNOSTIC',
          }}
        />
        <Box>
          {alertOpen && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                pt: 3,
              }}
            >
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity="warning"
                sx={{
                  borderRadius: '12px',
                  borderColor: '#FEC84B',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
              >
                <AlertTitle>
                  <strong>The date you've selected for pick-up is a holiday.</strong>
                </AlertTitle>
                Ensure that your service team is available on this day for timely completion.
              </Alert>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              mb: 0,
              mt: 3,
            }}
          >
            {/* <Stack
              spacing={1}
              sx={{
                width: '70%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontFamily: APP_FONT,
                  fontWeight: '700',
                  textAlign: 'left',
                  mb: 3,
                }}
              >
                1. Pick up time:
              </Typography>
              <Stack>
                <DatePicker
                  selected={pickupAfter}
                  onChange={handleTempDate}
                  showTimeSelect
                  timeFormat="p"
                  timeIntervals={15}
                  dateFormat="Pp"
                  placeholderText="mm/dd/yyyy --:-- --"
                  className="my-datepicker-input"
                  minDate={minDate}
                  minTime={getMinTime()}
                  maxTime={getMaxTime()}
                  maxDate={maxDate}
                  filterDate={isWeekday}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontFamily: APP_FONT,
                    fontWeight: '400',
                    color: '#393A3D',
                    width: 270,
                    paddingLeft: 1,
                    marginTop: '0px',
                    marginBottom: 2,
                  }}
                >
                  When will the car be ready for pick up?
                </Typography>
              </Stack>
            </Stack> */}
            <Stack
              spacing={1}
              marginY={4}
              sx={{
                width: '70%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontFamily: APP_FONT,
                  fontWeight: '700',
                  textAlign: 'left',
                  mb: 3,
                }}
              >
                Your Offer:
              </Typography>

              {service_request?.services.map((item: any) => (
                <Box
                  key={item.service_id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                      }}
                    >
                      {item.service.parent.parent_id ? item.service.parent.name : item.service.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.service.parent.parent_id && item.service.name}
                    </Typography>
                  </Stack>
                  <TextField
                    disabled={item.service.type === 'DIAGNOSIS'}
                    value={
                      servicePrices[item.service_id] ||
                      (item.service.type === 'DIAGNOSIS' ? '0.00' : '')
                    }
                    onChange={(event: any) => handleServicePriceChange(item.service_id, event)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      style: { background: '#fff' },
                    }}
                    inputProps={{
                      maxLength: 9,
                      style: { background: '#fff', height: '17px' },
                    }}
                    style={{ width: '30%' }}
                  />
                </Box>
              ))}
              <Divider
                sx={{
                  marginY: '16px !important',
                }}
              />

              <Box display="flex" gap={4}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}
                >
                  Subtotal:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#DB5D08',
                  }}
                >
                  ${fee}
                </Typography>
              </Box>
              <Divider
                sx={{
                  marginY: '16px !important',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Stack spacing={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                    }}
                  >
                    Shop supply fees
                  </Typography>
                </Stack>
                <TextField
                  value={shopSupplyFees}
                  onChange={handleFeeChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    style: { background: '#fff' },
                  }}
                  inputProps={{
                    maxLength: 9,
                    style: { background: '#fff', height: '17px' },
                  }}
                  style={{ width: '30%' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Stack spacing={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                    }}
                  >
                    Tax
                  </Typography>
                </Stack>
                <TextField
                  value={tax}
                  onChange={handleTaxChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    style: { background: '#fff' },
                  }}
                  inputProps={{
                    maxLength: 9,
                    style: { background: '#fff', height: '17px' },
                  }}
                  style={{ width: '30%' }}
                />
              </Box>
              <Divider
                sx={{
                  marginY: '16px !important',
                }}
              />
              <Box display="flex" gap={4}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}
                >
                  Total:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#DB5D08',
                  }}
                >
                  ${totalFee}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 3,
                  }}
                >
                  <Button
                    // disabled={isLoading || !allPricesSet() || !isPickupDateValid}
                    disabled={isLoading || !allPricesSet()}
                    onClick={handleOfferSubmission}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      borderRadius: 10,
                      marginTop: 3,
                      backgroundColor: '#FF6600',
                      height: 35,
                      width: 250,
                      ':hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: APP_FONT,
                        fontWeight: '700',
                      }}
                    >
                      SUBMIT OFFER
                    </div>
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
        {isLoading && (
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, height: '120vh' }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress
              size={60}
              sx={{
                color: '#FF6600',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-30px',
                marginLeft: '-30px',
              }}
            />
          </Backdrop>
        )}
      </Box>
    </Modal>
  )
}
