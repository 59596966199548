import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  TextField,
  MenuItem,
  InputAdornment,
  Alert,
  Divider,
  Stack,
} from '@mui/material'
import { createReccomendedServices } from '../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import ModalTableHead from './components/table_head'
import 'react-datepicker/dist/react-datepicker.css'
import { Offer } from '../../../../models/offer_new'
import { holidays, isSameDay } from '../../../../utils/holidays'
import DeleteIcon from '../../../../assets/images/delete.svg'
import Autocomplete from '@mui/material/Autocomplete'
import { useNavigate } from 'react-router-dom'
import { APP_FONT } from '../../../../constants/app_font'
import DatePicker from 'react-datepicker'
import { InfoChip } from './components/ui/info_chip'
import HoverPopper from '../../../../components/Hover/hover_popper'

interface ServiceChild {
  id: string
  name: string
}

interface Service {
  id: string
  name: string
  children: ServiceChild[]
}

interface RecommendedService {
  id: string
  name: string
  type: string
  types: any[]
  price: number
}

interface RecommendServicesModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  onClose: () => void
  offer: Offer
}

interface ServicePrices {
  [serviceId: string]: string
}

const RecommendServicesModal: React.FC<RecommendServicesModalProps> = ({
  setOpen,
  open,
  onClose,
  offer,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [services, setServices] = useState<Service[]>([])
  const [recServices, setRecServices] = useState<RecommendedService[]>([])
  const [pickupAfter, setPickupAfter] = useState<Date | null>(
    offer.pick_up_after ? new Date(offer.pick_up_after) : null
  )
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('Please select service and price')

  const dropOffTime = new Date(offer.pick_up_after)
  const today = new Date()
  let minDate = new Date(dropOffTime.getFullYear(), dropOffTime.getMonth(), dropOffTime.getDate())

  let maxDate = new Date(minDate)
  maxDate.setDate(maxDate.getDate() + 1)

  let minTimeToday = new Date()
  minTimeToday.setHours(dropOffTime.getHours(), dropOffTime.getMinutes() + 60, 0)
  const minTimeNextDays = new Date(new Date().setHours(8, 0, 0))

  const maxTime = new Date()
  maxTime.setHours(17, 0, 0)

  const handleServicePriceChange = (
    serviceId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setServicePrices((prevPrices) => ({
      ...prevPrices,
      [serviceId]: formattedNumber,
    }))
  }

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NEST_JS_API}service?group=true&limit=10&page=1`
        )
        const data = await response.json()
        const combinedChildren = data.items?.flatMap((category: any) => category.children) || []
        setServices(combinedChildren)
      } catch (error) {
        console.error('Error fetching services:', error)
      }
    }

    fetchServices()
  }, [])

  const handleServiceChange = (index: number, field: keyof RecommendedService, value: string) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          if (field === 'id') {
            const selectedService = services.find((s) => s.id === value)
            return {
              ...service,
              [field]: value,
              name: selectedService ? selectedService.name : '',
              type: '',
              types: selectedService ? selectedService.children : [],
            }
          } else {
            return { ...service, [field]: value }
          }
        }
        return service
      })
    )
  }

  const handleAddService = () => {
    const newService: RecommendedService = {
      id: `new_service_${recServices.length}`,
      name: '',
      type: '',
      types: [],
      price: 0,
    }

    setRecServices((prevServices) => [...prevServices, newService])
  }

  const handleRemoveService = (serviceId: string) => {
    setRecServices((prevServices) => prevServices.filter((service) => service.id !== serviceId))
    setServicePrices((prevPrices) => {
      const updatedPrices = { ...prevPrices }
      delete updatedPrices[serviceId]
      return updatedPrices
    })
  }

  const handleSubmit = async () => {
    if (recServices.some((service) => !service.name || !servicePrices[service.id])) {
      setAlertOpen(true)
      return
    }

    setAlertOpen(false)

    const formattedPickUpAfter = pickupAfter ? pickupAfter.toISOString() : null
    const formattedServices = recServices.map((service) => ({
      service_id: service.type ? service.type : service.id,
      price: parseFloat((servicePrices[service.id] ?? '0').replace(/[^0-9.-]+/g, '')) || 0,
    }))

    const payload = {
      pick_up_after: formattedPickUpAfter,
      supply_fee: parseFloat(shopSupplyFees.replace(/[^0-9.-]+/g, '')) || 0,
      tax: parseFloat(tax.replace(/[^0-9.-]+/g, '')) || 0,
      services: formattedServices,
    }

    try {
      dispatch<any>(createReccomendedServices({ offerId: offer.id, body: payload }))
        .then(unwrapResult)
        .then((data: any) => {
          navigate(0)
        })
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const renderServiceBlocks = () => (
    <>
      {recServices.map((service, index) => (
        <React.Fragment key={service.id}>
          <Grid container spacing={2} alignItems="center" mb={2}>
            <Grid item xs={5}>
              <Autocomplete
                value={
                  services.find((service: any) => service.id === recServices[index].id) || undefined
                }
                onChange={(event, newValue: any) => {
                  handleServiceChange(index, 'id', newValue?.id || '')
                }}
                options={services}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Service" placeholder="Select or search a service" />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: '100%' }}
                disableClearable
                freeSolo
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                select
                fullWidth
                label="Type"
                value={service.type}
                onChange={(e) => handleServiceChange(index, 'type', e.target.value)}
                disabled={!service.name || !service.types.length}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: '50vh',
                      },
                    },
                  },
                }}
              >
                {service.types.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Price"
                value={servicePrices[service.id] || ''}
                onChange={(e: any) => handleServicePriceChange(service.id, e)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px' },
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                disabled={recServices.length === 1}
                onClick={() => handleRemoveService(service.id)}
                size="large"
              >
                <img src={DeleteIcon} alt="Delete" />
              </IconButton>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </>
  )

  const isDateDisabled = (date: Date) => {
    return holidays.some((holiday) => isSameDay(date, holiday))
  }

  const handleTempDate = (date: Date) => {
    const isHoliday = isDateDisabled(date)
    setAlertOpen(isHoliday)
    setPickupAfter(date)
  }

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NEST_JS_API}service?group=true&limit=10&page=1`
        )
        const data = await response.json()
        const combinedChildren = data.items?.flatMap((category: any) => category.children) || []
        setServices(combinedChildren)
      } catch (error) {
        console.error('Error fetching services:', error)
      }
    }

    fetchServices()
  }, [])

  useEffect(() => {
    const newTotal = calculateSubTotalPrice()
    const formattedTotal = newTotal.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices, recServices])

  const calculateSubTotalPrice = (): number => {
    return Object.values(servicePrices).reduce((total, priceStr) => {
      const cleanPriceStr = priceStr.replace(/[^\d.]/g, '')
      const priceNumber = parseFloat(cleanPriceStr)

      if (!isNaN(priceNumber)) {
        const roundedPrice = Math.round(priceNumber * 100) / 100
        return total + roundedPrice
      }
      return total
    }, 0)
  }

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice()

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [recServices, servicePrices, shopSupplyFees, fee, tax])

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  const isSubmitDisabled =
    recServices.length === 0 ||
    recServices.some((service) => !service.name || !servicePrices[service.id]) ||
    !tax

  const renderSummary = () => {
    if (recServices.length === 0) {
      return null
    }

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={2}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                marginRight: '8px',
              }}
            >
              Subtotal:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#DB5D08',
              }}
            >
              ${fee}
            </Typography>
          </Box>
          <Divider sx={{ marginY: '16px !important' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box sx={{ mr: 2 }}>
            <HoverPopper holder={<InfoChip label="REVISED" />}>
              <Box
                sx={{
                  width: 'auto',
                  textAlign: 'center',
                  backgroundColor: 'rgba(238, 238, 238, 0.95)',
                  pointerEvents: 'auto',
                  padding: 2,
                  borderRadius: '4px',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.25)',
                  mx: 'auto',
                  transform: 'translateX(-50%)',
                  display: 'inline-block',
                  maxWidth: '90%',
                  position: 'absolute',
                  mt: 1,
                }}
              >
                Please provide the revised total shop supply fees for this entire order after adding
                the additional recommended services.
              </Box>
            </HoverPopper>
          </Box>

          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                pr: 2,
              }}
            >
              Shop supply fees:
            </Typography>
          </Stack>
          <TextField
            value={shopSupplyFees}
            onChange={handleFeeChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box sx={{ mr: 2 }}>
            <HoverPopper holder={<InfoChip label="REVISED" />}>
              <Box
                sx={{
                  width: 'auto',
                  textAlign: 'center',
                  backgroundColor: 'rgba(238, 238, 238, 0.95)',
                  pointerEvents: 'auto',
                  padding: 2,
                  borderRadius: '4px',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.25)',
                  mx: 'auto',
                  transform: 'translateX(-50%)',
                  display: 'inline-block',
                  maxWidth: '90%',
                  position: 'absolute',
                  mt: 1,
                }}
              >
                Please provide the revised total tax for this entire order after adding the
                additional recommended services.
              </Box>
            </HoverPopper>
          </Box>
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                pr: 2,
              }}
            >
              Tax:
            </Typography>
          </Stack>
          <TextField
            value={tax}
            onChange={handleTaxChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>

        <Box display="flex" gap={4}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mb: 2,
            }}
          >
            Total:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#DB5D08',
            }}
          >
            ${totalFee}
          </Typography>
        </Box>
        <Divider
          sx={{
            marginY: '16px !important',
          }}
        />
        {/* <Stack sx={{ display: 'flex' }}>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: APP_FONT,
              fontWeight: '700',
              textAlign: 'left',
              mb: 1,
              pl: 1,
            }}
          >
            Pick up time:
          </Typography>
          <DatePicker
            selected={pickupAfter}
            onChange={handleTempDate}
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            placeholderText="mm/dd/yyyy --:-- --"
            className="my-datepicker-input"
            minDate={minDate}
            maxDate={maxDate}
            minTime={pickupAfter && isSameDay(pickupAfter, today) ? minTimeToday : minTimeNextDays}
            maxTime={maxTime}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: APP_FONT,
              fontWeight: '400',
              color: '#393A3D',
              width: 270,
              paddingLeft: 1,
              marginTop: '0px',
              marginBottom: 2,
            }}
          >
            When will the car be ready for pick up?
          </Typography>
        </Stack> */}
      </>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box>
        <ModalTableHead
          onHide={onClose}
          service_request={{
            slug: offer.consumer_request.slug,
            vehicle: offer.vehicle,
            services: offer.services,
            drop_off_time: offer.drop_off_by,
          }}
        />
      </Box>
      {alertOpen && <Alert severity="error">{alertText}</Alert>}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF6600', mb: 1 }}>
              Services
            </Typography>
            {offer.services.map((service, index) => (
              <Box key={service.id || index}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                      }}
                    >
                      {service.service.parent.parent_id
                        ? service.service.parent.name
                        : service.service.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {service.service.parent.parent_id && service.service.name}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                    }}
                  >
                    ${service.price.toFixed(2)}
                  </Typography>
                </Box>
                {index < offer.services.length - 1 && <Divider />}
              </Box>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF6600', mb: 1 }}>
              Additional recommended services
            </Typography>
            {renderServiceBlocks()}
            <Button
              onClick={handleAddService}
              variant="outlined"
              color="warning"
              sx={{ mt: 1, textTransform: 'none' }}
            >
              Add more
            </Button>
            {renderSummary()}
            <Divider sx={{ marginY: '16px !important' }} />
            {recServices.length > 0 && (
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled}
                  sx={{
                    width: 'fit-content',
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                  }}
                >
                  Send for approval
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default RecommendServicesModal
