import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { Store } from './redux/store'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './pages/authentication/login/login'
import Register from './pages/authentication/register/register'
import Dashboard from './pages/dashboard/dashboard'
import Settings from './pages/setting/settings'
import '../src/pages/dashboard/tables/modals/styles.css'
import ResetPassword from './pages/authentication/reset_password/reset_password'
import ForgotPassword from './pages/authentication/forgot_password/forgot_password'
import Profile from './pages/profile'
import Services from './pages/service/services'
import Perks from './pages/perks/perks'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          {/* <Route path="/services" element={<Services />} /> */}
          <Route path="/perks" element={<Perks />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
