import React, { useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
  Stack,
  Divider,
  TextField,
  InputAdornment,
  Alert,
  AlertTitle,
} from '@mui/material'
import axios from 'axios'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import File from './../../../../assets/images/file_icon.svg'
import { useDispatch } from 'react-redux'
import { updateOfferRevisedTax, uploadPdf } from '../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import ModalTableHead from './components/table_head'
import FilePreview from './components/file_preview'
import { Offer } from '../../../../models/offer_new'
import { APP_FONT } from '../../../../constants/app_font'
import DatePicker from 'react-datepicker'
import { isSameDay } from '../../../../utils/holidays'
import { ReactComponent as PdfIcon } from '../../../../assets/images/pdf_download.svg'
import { useNavigate } from 'react-router-dom'
import { formatPrice } from '../../../../components/helper/helper'

const MAX_FILE_SIZE = 25 * 1024 * 1024
interface InvoiceUploadModalProps {
  setOpen: (arg: boolean) => void
  open: boolean
  onClose: () => void
  onSubmit: (file: File) => void
  offer: Offer
}

interface FileState {
  INVOICE: File | null
}

const InvoiceUploadModal: React.FC<InvoiceUploadModalProps> = ({
  setOpen,
  open,
  onClose,
  onSubmit,
  offer,
}) => {
  const dispatch = useDispatch()
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'>(
    'IDLE'
  )
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [diagnosticData, setDiagnosticData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [pickupAfter, setPickupAfter] = useState<Date | null>(
    offer.pick_up_after ? new Date(offer.pick_up_after) : null
  )
  const navigate = useNavigate()

  const dropOffTime = new Date(offer.pick_up_after)
  const today = new Date()
  let minDate = new Date(dropOffTime.getFullYear(), dropOffTime.getMonth(), dropOffTime.getDate())

  let maxDate = new Date(minDate)
  maxDate.setDate(maxDate.getDate() + 1)

  let minTimeToday = new Date()
  minTimeToday.setHours(dropOffTime.getHours(), dropOffTime.getMinutes() + 60, 0)
  const minTimeNextDays = new Date(new Date().setHours(8, 0, 0))

  const maxTime = new Date()
  maxTime.setHours(17, 0, 0)

  const handleTempDate = (date: Date) => {
    setPickupAfter(date)
  }

  useEffect(() => {
    const fetchDiagnosticData = async () => {
      setIsLoading(true)
      try {
        const userToken = localStorage.getItem('userToken')
        const response = await axios.get(
          `${process.env.REACT_APP_NEST_JS_API}provider/request/file/${offer.id}/ESTIMATE`,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        setDiagnosticData(response.data[0])
      } catch (error) {
        console.error('Error fetching diagnostic file:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchDiagnosticData()
  }, [])

  const handleSubmit = (file: File) => {
    if (offer.services_fully_approved) {
      onSubmit(file)
      return
    }

    const payload = {
      supply_fee: parseFloat(shopSupplyFees),
      tax: parseFloat(tax),
      pick_up_after: pickupAfter,
    }
    try {
      dispatch<any>(updateOfferRevisedTax({ offerId: offer.id, body: payload }))
        .then(unwrapResult)
        .then((data: any) => {
          onSubmit(file)
        })
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileSelect = (selectedFile: File) => {
    if (selectedFile.size > MAX_FILE_SIZE) {
      alert('File is too large. Maximum size is 10MB.')
      return
    }
    setFile(selectedFile)
    setUploadStatus('IDLE')
  }
  const handleUpload = () => {
    if (!file) {
      console.error('No file provided.')
      setUploadStatus('ERROR')
      return
    }
    setUploadStatus('UPLOADING')

    handleSubmit(file)
    setUploadStatus('SUCCESS')
  }

  const uploadFeedback = () => {
    switch (uploadStatus) {
      case 'UPLOADING':
        return <Typography color="primary">Uploading...</Typography>
      case 'SUCCESS':
        return <Typography color="green">Upload successful!</Typography>
      case 'ERROR':
        return <Typography color="error">Upload failed. Try again.</Typography>
      default:
        return null
    }
  }

  const renderDroppableArea = (fileType: keyof FileState) => (
    <div
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => {
        event.preventDefault()
        if (event.dataTransfer.files[0]) {
          handleFileSelect(event.dataTransfer.files[0])
        }
      }}
      style={{
        border: '2px dashed #C4C4C4',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '16px',
        transition: 'background-color 0.3s',
        backgroundColor: '#FAFAFA',
      }}
    >
      <CloudUploadIcon style={{ fontSize: '50px', color: '#C4C4C4' }} />
      <Typography>
        <Button
          variant="text"
          component="span"
          style={{ textTransform: 'none', padding: 0 }}
          color="warning"
          onClick={handleUploadClick}
        >
          Click to upload
        </Button>{' '}
        or drag and drop
      </Typography>
      <Typography variant="caption">PDF, PNG, JPG, or JPEG (max. 25 MB)</Typography>
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="file-input"
        type="file"
        accept="application/pdf, image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files?.[0]) {
            handleFileSelect(e.target.files[0])
          }
        }}
      />

      {file && (
        <>
          <Typography variant="body2">{`Selected file: ${file.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
    </div>
  )

  const handleCloseClick = (e: any) => {
    e.stopPropagation()
    setOpen(false)
  }

  //summary
  useEffect(() => {
    const newTotal = calculateSubTotalPrice()
    const formattedTotal = newTotal.toFixed(2)
    setFee(formattedTotal)
  }, [])

  useEffect(() => {
    const tax = parseFloat(offer.tax || offer.new_tax || '0.00').toFixed(2)
    const supply_fee = parseFloat(offer.supply_fee || offer.new_supply_fee || '0.00').toFixed(2)
    setTax(tax)
    setShopSupplyFees(supply_fee)
  }, [])

  const calculateSubTotalPrice = (): number => {
    return offer.services
      .filter((service) => service.is_approved)
      .reduce((total, service) => total + service.price, 0)
  }

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees?.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax?.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice()

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [shopSupplyFees, fee, tax])

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  const renderDiagnosticFile = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, color: '#FF6600' }}>
          Diagnostic results:
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Diagnostic Report
        </Typography>
        <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
          Includes recommended services to resolve issues identified during diagnostics.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '16px',
            padding: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            backgroundColor: 'white',
            width: '50%',
          }}
        >
          <a href={diagnosticData?.url} target="_blank" rel="noopener noreferrer">
            <PdfIcon style={{ cursor: 'pointer' }} />
          </a>
          <div>
            <Typography variant="body2">{diagnosticData?.name}</Typography>
            <Typography variant="caption">{`${(diagnosticData?.size / 1024 / 1024).toFixed(
              2
            )} MB`}</Typography>
          </div>
        </Box>
      </Grid>
    )
  }

  const renderSummary = () => {
    if (offer.services.filter((service) => service.is_approved === true).length === 0) {
      return null
    }

    return (
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              mt: 5,
              pt: 3,
              width: '50%',
            }}
          >
            <Alert
              severity="warning"
              sx={{
                borderRadius: '12px',
                borderColor: '#FEC84B',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
            >
              <AlertTitle>
                <strong>Add revised shop supply fee and taxes</strong>
              </AlertTitle>
              Please add revised shop supply fee and taxes as only partial recommended services got
              accepted by customer
            </Alert>
          </Box>
          <Box
            sx={{
              width: '50%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={2}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#393A3D',
                    marginRight: '8px',
                  }}
                >
                  Subtotal:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#DB5D08',
                  }}
                >
                  {fee && formatPrice(fee)}
                </Typography>
              </Box>
              <Divider sx={{ marginY: '16px !important' }} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Stack spacing={1}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#393A3D',
                    pr: 2,
                  }}
                >
                  Shop supply fees:
                </Typography>
              </Stack>
              <TextField
                value={shopSupplyFees}
                onChange={handleFeeChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px' },
                }}
                style={{ width: '30%' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Stack spacing={1}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#393A3D',
                    pr: 2,
                  }}
                >
                  Tax:
                </Typography>
              </Stack>
              <TextField
                value={tax}
                onChange={handleTaxChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px' },
                }}
                style={{ width: '30%' }}
              />
            </Box>

            <Box display="flex" gap={4}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#393A3D',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                Total:
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  color: '#DB5D08',
                }}
              >
                {totalFee && formatPrice(totalFee)}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* <Stack sx={{ display: 'flex' }}>
          <Divider
            sx={{
              marginY: '16px !important',
            }}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: APP_FONT,
              fontWeight: '700',
              textAlign: 'left',
              mb: 1,
              pl: 1,
            }}
          >
            Pick up time:
          </Typography>
          <DatePicker
            selected={pickupAfter}
            onChange={handleTempDate}
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            placeholderText="mm/dd/yyyy --:-- --"
            className="my-datepicker-input"
            minDate={minDate}
            maxDate={maxDate}
            minTime={pickupAfter && isSameDay(pickupAfter, today) ? minTimeToday : minTimeNextDays}
            maxTime={maxTime}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: APP_FONT,
              fontWeight: '400',
              color: '#393A3D',
              width: 270,
              paddingLeft: 1,
              marginTop: '0px',
              marginBottom: 2,
            }}
          >
            When will the car be ready for pick up?
          </Typography>
        </Stack> */}
      </Box>
    )
  }

  const renderInvoiceFeedback = () => {
    const instruction = offer.files.find((el) => el.type === 'INVOICE')?.instruction_admin_provider
    if (!instruction) return null

    return (
      <Box
        sx={{
          mt: 2,
          mb: 2,

          p: 2,
          border: 1,
          borderColor: '#FFB38C',
          borderRadius: 'borderRadius',
          bgcolor: 'background.paper',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#FF6600' }}>
          Carma Technician Feedback
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: '500', mb: 2, color: '#1D2939' }}>
          {instruction}
        </Typography>
      </Box>
    )
  }

  return (
    <Dialog open={open} onClose={handleCloseClick} maxWidth="md" fullWidth>
      <Box>
        <ModalTableHead
          onHide={onClose}
          service_request={{
            slug: offer.consumer_request.slug,
            vehicle: offer.vehicle,
            services: offer.services,
            drop_off_time: offer.drop_off_by,
            is_diagnostic: offer.consumer_request.type === 'DIAGNOSTIC',
          }}
        />
      </Box>
      <DialogContent>
        {renderInvoiceFeedback()}
        {offer?.consumer_request.files.length > 0 ? (
          <>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Photos and video by customer
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 2 }}>
              Uploaded by the customer to explain the issue
            </Typography>
            <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
              <FilePreview files={offer?.consumer_request.files || []} />
            </Box>
          </>
        ) : (
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            No media was uploaded by the customer
          </Typography>
        )}
        <Grid container spacing={2}>
          {diagnosticData ? renderDiagnosticFile() : ''}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF6600', mb: 1 }}>
              Approved services
            </Typography>
            {offer.services
              .filter((service) => service.is_approved === true)
              .map((service, index) => (
                <Box key={service.id || index}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontFamily: APP_FONT,
                          fontWeight: '600',
                          color: '#393A3D',
                        }}
                      >
                        {service.service.parent.parent_id
                          ? service.service.parent.name
                          : service.service.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {service.service.parent.parent_id && service.service.name}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#FF6600',
                      }}
                    >
                      {formatPrice(service.price)}
                    </Typography>
                  </Box>
                  {index < offer.services.length - 1 && <Divider />}
                </Box>
              ))}
            {!offer?.services_fully_approved ? renderSummary() : ''}
            {offer?.services_fully_approved ? (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={2}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                        marginRight: '8px',
                      }}
                    >
                      Subtotal:
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#DB5D08',
                      }}
                    >
                      {fee && formatPrice(fee)}
                    </Typography>
                  </Box>
                  <Divider sx={{ marginY: '16px !important' }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                        pr: 2,
                      }}
                    >
                      Shop supply fees:
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {shopSupplyFees && formatPrice(shopSupplyFees)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                        pr: 2,
                      }}
                    >
                      Tax:
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {tax && formatPrice(tax)}
                  </Typography>
                </Box>

                <Box display="flex" gap={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {totalFee && formatPrice(totalFee)}
                  </Typography>
                </Box>
              </Box>
            ) : (
              ''
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Final Invoice
            </Typography>
            <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
              Upload the final invoice that only includes the services the customer has accepted
            </Typography>
            {renderDroppableArea('INVOICE')}
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="contained"
                color="warning"
                disabled={
                  !file ||
                  (uploadStatus !== 'IDLE' && uploadStatus !== 'SUCCESS') ||
                  (offer.services.filter((service) => service.is_approved === true).length > 0 &&
                    !tax &&
                    !offer?.services_fully_approved)
                }
                onClick={() => {
                  handleUpload()
                }}
                sx={{
                  width: 'fit-content',
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  paddingLeft: '32px',
                  paddingRight: '32px',
                }}
              >
                Submit for invoice approval
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  )
}

export default InvoiceUploadModal
