import { useEffect, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CssBaseline,
  Backdrop,
  CircularProgress,
  Switch,
  IconButton,
  Button,
} from '@mui/material'
import { DragDropContext, Droppable, Draggable, DroppableProps } from 'react-beautiful-dnd'
import { visuallyHidden } from '@mui/utils'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { APP_FONT } from '../../constants/app_font'
import { AppHeader } from '../../components/header/app_header'
import PerkIcon from '../../assets/images/perk_icon.svg'
import {
  createPerk,
  deletePerk,
  fetchPerks,
  updatePerkMetaData,
  updatePerkOrder,
} from '../../redux/actions/perks'
import DynamicEditModal from './modals/edit-perk'
import EditIcon from '@mui/icons-material/Edit'
import { Metadata, ShopPerk } from './models/shop-perk'
interface Data {
  icon: string
  description: string
  included: string
  action: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'icon',
    disablePadding: false,
    label: 'Icon',
  },
  {
    id: 'description',
    disablePadding: true,
    label: 'Description',
  },
  {
    id: 'included',
    disablePadding: false,
    label: 'Does your shop offer this perk?',
  },
  {
    id: 'action',
    disablePadding: false,
    label: '',
  },
]

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={'left'}>
            <div style={{ fontWeight: '600', fontFamily: APP_FONT }}>{headCell.label}</div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface PerkWithMetadata extends ShopPerk {
  updatedMetadata?: Metadata | null
}

interface PerkOrder {
  [perkId: string]: number
}

export const StrictModeDroppable = (props: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))

    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props} />
}

const Perks = () => {
  const [selected] = useState<readonly string[]>([])
  const [perks, setPerks] = useState<any[]>([])
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [perk, setCurrentPerkToEdit] = useState<ShopPerk | null>(null)
  const [perksWithMetadata, setPerksWithMetadata] = useState<PerkWithMetadata[]>([])
  const [perksOrder, setPerksOrder] = useState<PerkOrder>({})

  const getPerks = () => {
    const providerBranchId = localStorage.getItem('branchId')
    setLoading(true)
    if (providerBranchId)
      dispatch<any>(
        fetchPerks({
          provider_branch_id: providerBranchId,
          limit: 100,
          page: 1,
        })
      )
        .then(unwrapResult)
        .then(async (data: any) => {
          const sortedPerks = data.items.sort((a: any, b: any) => {
            if (a.is_included && !b.is_included) return -1
            if (!a.is_included && b.is_included) return 1
            return perksOrder[a.id] - perksOrder[b.id]
          })
          setPerks(sortedPerks)
          setLoading(false)
        })
        .catch((error: any) => {
          console.log(error)
          setLoading(false)
        })
  }

  useEffect(() => {
    getPerks()
  }, [])

  useEffect(() => {
    setPerksWithMetadata(perks.map((perk) => ({ ...perk, updatedMetadata: perk.meta_data })))
  }, [perks])

  const isMetadataFilled = (metadata: Metadata | null | undefined): boolean => {
    return metadata
      ? Object.values(metadata).every((value) => value !== null && value !== '')
      : false
  }

  const handlePerkCreate = async (perk: PerkWithMetadata) => {
    const providerBranchId = localStorage.getItem('branchId')

    setLoading(true)
    if (providerBranchId) {
      try {
        const result = await dispatch<any>(
          createPerk({
            provider_branch_id: providerBranchId,
            shop_perk_ids: [{ perk_id: perk.id, meta_data: perk.updatedMetadata }],
          })
        )
        unwrapResult(result)
        getPerks()
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const handlePerkRemove = async (perk: ShopPerk) => {
    const providerBranchId = localStorage.getItem('branchId')
    setLoading(true)
    if (providerBranchId) {
      try {
        const result = await dispatch<any>(
          deletePerk({
            provider_branch_id: providerBranchId,
            shop_perk_ids: [perk.id],
          })
        )
        unwrapResult(result)
        getPerks()
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleSaveEditedPerk(updatedPerk: ShopPerk, editFields: Metadata) {
    const providerBranchId = localStorage.getItem('branchId')

    setLoading(true)

    try {
      if (
        updatedPerk.provider_branch &&
        updatedPerk.provider_branch.meta_data &&
        providerBranchId
      ) {
        const updateResult = await dispatch<any>(
          updatePerkMetaData({
            provider_branch_id: providerBranchId,
            shop_perk_id: updatedPerk.id,
            meta_data: editFields,
          })
        ).then(unwrapResult)

        if (updateResult) {
          await getPerks()
        }
      } else {
        setPerksWithMetadata((currentPerks) =>
          currentPerks.map((perk) =>
            perk.id === updatedPerk.id ? { ...perk, updatedMetadata: editFields } : perk
          )
        )
      }
    } catch (error) {
      console.error('Failed to update perk:', error)
    } finally {
      setLoading(false)
      setEditModalOpen(false)
    }
  }

  const handleEditButtonClick = (perk: ShopPerk) => {
    setCurrentPerkToEdit(perk)
    setEditModalOpen(true)
  }

  const handleToggle = (row: PerkWithMetadata) => {
    setLoading(true)
    const updatedPerks = perks.map((perk) => {
      if (perk.id === row.id) {
        const updatedPerk = { ...perk, is_included: !perk.is_included }

        if (updatedPerk.is_included) {
          handlePerkCreate(row)
        } else {
          handlePerkRemove(row)
        }

        return updatedPerk
      }
      return perk
    })

    setPerks(updatedPerks)
  }

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging ? '#FF7D26' : '',
    ...draggableStyle,
  })

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return
    }

    const { updatedItems, movedPerks } = reorder(
      perks,
      result.source.index,
      result.destination.index
    )

    if (movedPerks.length === 0) {
      return
    }

    const updatedOrder = movedPerks.map((perk) => ({
      shop_perk_id: perk.id,
      order: updatedItems.findIndex((p) => p.id === perk.id),
    }))

    const providerBranchId = localStorage.getItem('branchId')
    if (providerBranchId) {
      try {
        setLoading(true)
        const updateResult = await dispatch<any>(
          updatePerkOrder({
            provider_branch_id: providerBranchId,
            items: updatedOrder,
          })
        ).then(unwrapResult)

        if (updateResult) {
          setPerks(updatedItems)
        }
      } catch (error) {
        console.error('Failed to update perk order:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  const reorder = (
    list: ShopPerk[],
    startIndex: number,
    endIndex: number
  ): { updatedItems: ShopPerk[]; movedPerks: ShopPerk[] } => {
    const result = Array.from(list)
    const movedPerks = []
    const movedItem = result[startIndex]

    if (!movedItem.is_included) {
      return { updatedItems: list, movedPerks: [] }
    }

    result.splice(startIndex, 1)
    result.splice(endIndex, 0, movedItem)
    movedPerks.push(movedItem)

    for (let i = 0; i < result.length; i++) {
      if (
        result[i].is_included &&
        result[i].id !== movedItem.id &&
        (i < startIndex || i > endIndex)
      ) {
        movedPerks.push(result[i])
      }
    }

    return { updatedItems: result, movedPerks }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppHeader />
      <Box
        component="main"
        sx={{
          px: 8,
          py: 15,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            fontWeight={700}
            fontFamily={APP_FONT}
            fontSize={32}
            color={'#FF6600'}
            mt={3}
            mb={3}
          >
            Shop perks
          </Typography>
        </Box>
        <Box>
          <Paper>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={'small'}>
                <EnhancedTableHead />
                <DragDropContext onDragEnd={onDragEnd}>
                  <StrictModeDroppable droppableId="droppable">
                    {(provided) => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {perksWithMetadata.map((row, index) => (
                          <Draggable key={row.id} draggableId={row.id} index={index}>
                            {(provided, snapshot) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                                  {
                                    <IconButton>
                                      <img src={row.icon ? row.icon : PerkIcon} alt="edit" />
                                    </IconButton>
                                  }
                                </TableCell>

                                <TableCell
                                  sx={{ fontFamily: APP_FONT, width: '60%' }}
                                  align="left"
                                  {...provided.dragHandleProps}
                                >
                                  {row.description ?? '-'}
                                </TableCell>
                                <TableCell
                                  sx={{ fontFamily: APP_FONT }}
                                  align="left"
                                  {...provided.dragHandleProps}
                                >
                                  <Switch
                                    checked={row.is_included}
                                    color="warning"
                                    onChange={() => handleToggle(row)}
                                    disabled={
                                      row.meta_data &&
                                      !isMetadataFilled(row.provider_branch.meta_data) &&
                                      !isMetadataFilled(row.updatedMetadata) &&
                                      !row.is_included
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{ fontFamily: APP_FONT }}
                                  align="left"
                                  {...provided.dragHandleProps}
                                >
                                  {row?.meta_data && (
                                    <Button
                                      variant="contained"
                                      startIcon={<EditIcon sx={{ color: 'black' }} />}
                                      onClick={() => handleEditButtonClick(row)}
                                      sx={{
                                        margin: 'auto',
                                        display: 'flex',
                                        textTransform: 'none',
                                        backgroundColor: '#E0E0E0',
                                        '&:hover': {
                                          backgroundColor: '#d5d5d5',
                                        },
                                        color: 'black',
                                        borderRadius: 6,
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <DynamicEditModal
          open={isEditModalOpen}
          handleClose={() => {
            setEditModalOpen(false)
            setCurrentPerkToEdit(null)
          }}
          perk={perk}
          onSave={handleSaveEditedPerk}
        />
        {isLoading && (
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    </Box>
  )
}

export default Perks
