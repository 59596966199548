import React, { useState } from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  TableContainer,
  styled,
  Table,
  Box,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { APP_FONT } from '../../../../../constants/app_font'
import HoverPopper from '../../../../../components/Hover/hover_popper'
import Copy from '../../../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../../../assets/images/contentCopyFilled.svg'
import { formatDateTime } from '../../../../../utils/helper'
import { ArrowTooltip } from './ui/arrow_tooltip'

interface TableHeadProps {
  onHide: () => void
  service_request: {
    slug: string
    vehicle: any
    services: any[]
    drop_off_time: string
    is_diagnostic?: boolean
  }
}

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
  }
`

const ModalTableHead: React.FC<TableHeadProps> = ({ onHide, service_request }) => {
  const [copiedVin, setCopiedVin] = useState<string | null>(null)
  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => setCopiedVin(null), 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  return (
    <TableContainer>
      <Table size="medium" sx={{ width: '100%' }}>
        <TableHead sx={{ backgroundColor: '#FF6600' }}>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Request ID
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Vehicle
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Vehicle ID
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              VIN
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Services
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: '700',
                fontFamily: APP_FONT,
                fontSize: 14,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              Drop-off time
            </TableCell>
            <TableCell align="right">
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                sx={{ color: 'white' }}
                onClick={onHide}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT, width: 70 }}>
              {service_request.slug}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle.year} {service_request.vehicle.make}{' '}
              {service_request.vehicle.model} {service_request.vehicle.trim ?? ''}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle.name}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                {service_request.vehicle?.vin || 'VIN not provided'}
                {service_request.vehicle?.vin && (
                  <ArrowTooltip title={copiedVin === service_request.vehicle?.vin ? 'Copied!' : ''}>
                    <StyledButton
                      onClick={(event: any) =>
                        handleCopyVin(event, service_request.vehicle?.vin ?? '')
                      }
                    >
                      <img
                        src={copiedVin === service_request.vehicle?.vin ? CopyFilled : Copy}
                        alt=""
                      />
                    </StyledButton>
                  </ArrowTooltip>
                )}
              </div>
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {!service_request?.is_diagnostic ? (
                <HoverPopper holder={`(${service_request?.services?.length}) see all`}>
                  <Box
                    sx={{
                      backgroundColor: 'rgba(238, 238, 238, 0.95)',
                      pointerEvents: 'auto',
                      paddingTop: 3,
                      paddingLeft: 3,
                      paddingRight: 3,
                      paddingBottom: 1,
                    }}
                  >
                    {service_request?.services?.map((item: any) => {
                      let displayName = item?.service.name
                      if (
                        item?.service?.parent &&
                        item.service.parent.parent_id &&
                        item.service.parent.name
                      ) {
                        displayName = `${item.service.parent.name}  
                      (${displayName}${
                          item.service.type === 'TIRE' &&
                          service_request.vehicle.tires &&
                          service_request.vehicle.tires.length > 0
                            ? `, ${service_request.vehicle.tires[0].width}/${service_request.vehicle.tires[0].ratio}${service_request.vehicle.tires[0].construction}${service_request.vehicle.tires[0].diameter}`
                            : ''
                        })`
                      }
                      return (
                        <Typography
                          key={`a-${item.service.name}`}
                          mb={1.5}
                          fontFamily={APP_FONT}
                          fontSize={'14px'}
                        >
                          {displayName}{' '}
                        </Typography>
                      )
                    })}
                  </Box>
                </HoverPopper>
              ) : (
                <HoverPopper holder={`(1) see all`}>
                  <Box
                    sx={{
                      backgroundColor: 'rgba(238, 238, 238, 0.95)',
                      pointerEvents: 'auto',
                      paddingTop: 3,
                      paddingLeft: 3,
                      paddingRight: 3,
                      paddingBottom: 1,
                    }}
                  >
                    <Typography mb={1.5} fontFamily={APP_FONT} fontSize={'14px'}>
                      {'Full general diagnostic'}
                    </Typography>
                  </Box>
                </HoverPopper>
              )}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT, width: 100 }}>
              <Box>
                {service_request.drop_off_time && formatDateTime(service_request.drop_off_time)}{' '}
              </Box>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ModalTableHead
