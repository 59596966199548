import * as React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Paper,
  styled,
  IconButton,
  ListItem,
  List,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useDispatch } from 'react-redux'
import { fetchOffers } from '../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import socket from '../../../sockets/socket'
import { APP_FONT } from '../../../constants/app_font'
import { ReactComponent as CompletedOffersIcon } from '../../../assets/images/completed_icon.svg'
import HoverPopper from '../../../components/Hover/hover_popper'
import { formatDateTime } from '../../../utils/helper'
import useLocalTimezoneDates from '../../../utils/localizeTimezone'
import Copy from '../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../assets/images/contentCopyFilled.svg'
import { Offer } from '../../../models/offer_new'
import { ArrowTooltip } from './modals/components/ui/arrow_tooltip'
import { useState } from 'react'

interface Data {
  OrderID: string
  CompletedOn: string
  Vehicle: string
  VehicleId: string
  VIN: string
  VehicleClass: string
  EngineType: string
  EngineSize: string
  CustomerPhone: string
  Services: string
  OfferAmount: string
  Timeblock: string
  PickupTime: string
  Comments: string
}

const StickyHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  top: 0,
  zIndex: 10,
  position: 'sticky',
  boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)',
}))

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
    margin: 12px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
    margin: 12px;
  }
`

const rows = []

type Order = 'asc' | 'desc'

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'OrderID',
    disablePadding: true,
    label: 'Order ID',
  },
  {
    id: 'CompletedOn',
    disablePadding: true,
    label: 'Completed on',
  },
  {
    id: 'Vehicle',
    disablePadding: false,
    label: 'Vehicle',
  },
  {
    id: 'VehicleId',
    disablePadding: false,
    label: 'Vehicle ID',
  },
  {
    id: 'VIN',
    disablePadding: false,
    label: 'VIN',
  },
  {
    id: 'VehicleClass',
    disablePadding: false,
    label: 'Vehicle class',
  },
  {
    id: 'EngineType',
    disablePadding: false,
    label: 'Engine type',
  },
  {
    id: 'EngineSize',
    disablePadding: false,
    label: 'Engine size',
  },
  {
    id: 'Services',
    disablePadding: false,
    label: 'Services',
  },
  {
    id: 'OfferAmount',
    disablePadding: false,
    label: 'Offer amount',
  },
  {
    id: 'Timeblock',
    disablePadding: false,
    label: 'Drop-off time',
  },
  {
    id: 'PickupTime',
    disablePadding: false,
    label: 'Pick up time',
  },
  {
    id: 'Comments',
    disablePadding: false,
    label: 'Comments',
  },
]

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StickyHeaderTableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div style={{ fontWeight: '600', fontFamily: APP_FONT }}>{headCell.label}</div>
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </StickyHeaderTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      ></Typography>
    </Toolbar>
  )
}

type Props = {
  formatCurrency: any
}

export default function Completed(props: Props) {
  const { formatCurrency } = props
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<keyof Data>('OrderID')
  const [selected] = useState<readonly string[]>([])
  const [copiedVin, setCopiedVin] = useState<string | null>(null)
  const [copiedComment, setCopiedComment] = useState<string | null>(null)
  const [completedOffers, setCompletedOffers] = useState<Offer[]>([])
  const dispatch = useDispatch()

  const localCompletedOffers = useLocalTimezoneDates(completedOffers, [
    'provider_drop_off_by',
    'drop_off_by',
    'pick_up_after',
    'created_at',
  ])

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => {
          setCopiedVin(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyComment = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedComment(vin)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const fetchCompletedServices = () => {
    const providerId = localStorage.getItem('branchId')
    if (providerId)
      dispatch<any>(fetchOffers({ statuses: ['READY_FOR_PICKUP', 'COMPLETED'], providerId }))
        .then(unwrapResult)
        .then((data: any) => {
          setCompletedOffers(data)
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('error')
        })
  }

  React.useEffect(() => {
    fetchCompletedServices()

    const providerId = localStorage.getItem('branchId')
    if (providerId) {
      socket.emit('subscribe', { branchId: providerId })
      socket.on('status-update', (payload) => {
        fetchCompletedServices()
      })
    }

    return () => {
      socket.off('status-update')
    }
  }, [])

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: -8,
        }}
      >
        <CompletedOffersIcon />
        <Typography
          sx={{
            marginTop: 4,
            fontFamily: APP_FONT,
            fontSize: 14,
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: '#AEAEAE',
          }}
          id="tableTitle"
          component="div"
        >
          Completed
        </Typography>
      </Box>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer sx={{ px: 2, maxHeight: 800, overflowY: 'auto' }}>
          <Table aria-labelledby="tableTitle" size={'small'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={rows.length}
              onRequestSort={function (
                event: React.MouseEvent<unknown, MouseEvent>,
                property: keyof Data
              ): void {
                throw new Error('Function not implemented.')
              }}
              onSelectAllClick={function (event: React.ChangeEvent<HTMLInputElement>): void {
                throw new Error('Function not implemented.')
              }}
            />
            <TableBody>
              {localCompletedOffers?.map((row: Offer) => (
                <TableRow key={row.id}>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {row.consumer_request.slug ?? row.consumer_request.id}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {formatDateTime(row.created_at)}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {`${row?.vehicle?.year} ${row?.vehicle?.make} ${row?.vehicle?.model} ${
                      row.vehicle.trim ?? ''
                    }` || 'Null'}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }}>{row.vehicle?.name}</TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                      {row.vehicle?.vin || 'VIN not provided'}
                      {row.vehicle?.vin && (
                        <ArrowTooltip title={copiedVin === row.vehicle?.vin ? 'Copied!' : ''}>
                          <StyledButton
                            onClick={(event) => handleCopyVin(event, row.vehicle?.vin ?? '')}
                          >
                            <img src={copiedVin === row.vehicle?.vin ? CopyFilled : Copy} alt="" />
                          </StyledButton>
                        </ArrowTooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {row.vehicle?.class?.name ? `Class ${row.vehicle?.class?.name}` : ''}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {[row.vehicle?.engines].join(', ')}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {row.vehicle?.engine_size}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <Box>
                      <List sx={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        {(row?.services?.length > 0
                          ? row?.services?.slice(0, 3)
                          : [{ service: { name: 'Full general diagnostic' } }]
                        ).map((item, index) => {
                          let displayName = item?.service.name
                          if (
                            item?.service?.parent &&
                            item.service.parent.parent_id &&
                            item.service.parent.name
                          ) {
                            displayName = `${item.service.parent.name} (${displayName}${
                              item.service.type === 'TIRE' &&
                              row.vehicle.tires &&
                              row.vehicle.tires.length > 0
                                ? `, ${row.vehicle.tires[0].width}/${row.vehicle.tires[0].ratio}${row.vehicle.tires[0].construction}${row.vehicle.tires[0].diameter}`
                                : ''
                            })`
                          }

                          return (
                            <ListItem
                              key={`a-${item.service.name}-${index}`}
                              sx={{ display: 'list-item', padding: 0 }}
                            >
                              <Typography mb={1.5} fontFamily={APP_FONT} fontSize={'14px'}>
                                {displayName}
                              </Typography>
                            </ListItem>
                          )
                        })}
                      </List>
                    </Box>

                    {row?.services?.length - 3 > 0 && (
                      <HoverPopper
                        holder={
                          <Typography
                            sx={{
                              color: '#DB5D08',
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {`(${row?.services?.length || 1}) View all`}
                          </Typography>
                        }
                      >
                        <Box
                          sx={{
                            backgroundColor: 'rgba(238, 238, 238, 0.95)',
                            pointerEvents: 'auto',
                            paddingTop: 3,
                            paddingLeft: 3,
                            paddingRight: 3,
                            paddingBottom: 1,
                          }}
                        >
                          {row.services.map((item, index) => {
                            let displayName = item?.service.name
                            if (
                              item?.service?.parent &&
                              item.service.parent.parent_id &&
                              item.service.parent.name
                            ) {
                              displayName = `${item.service.parent.name} (${displayName}${
                                item.service.type === 'TIRE' &&
                                row.vehicle.tires &&
                                row.vehicle.tires.length > 0
                                  ? `, ${row.vehicle.tires[0].width}/${row.vehicle.tires[0].ratio}${row.vehicle.tires[0].construction}${row.vehicle.tires[0].diameter}`
                                  : ''
                              })`
                            }

                            return (
                              <Typography
                                key={`a-${item.service.name}-${index}`}
                                mb={1.5}
                                fontFamily={APP_FONT}
                                fontSize={'14px'}
                              >
                                {displayName}
                              </Typography>
                            )
                          })}
                        </Box>
                      </HoverPopper>
                    )}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {formatCurrency.format(row.total_fee) || 'Null'}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {row.provider_drop_off_by
                      ? formatDateTime(row.provider_drop_off_by)
                      : formatDateTime(row.drop_off_by) || 'Not Selected'}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {formatDateTime(row.pick_up_after) || 'Not Selected'}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                      {row.consumer_request.additional_information ? (
                        <>
                          {row.consumer_request.additional_information.length > 104 ? (
                            <HoverPopper
                              holder={
                                <span>
                                  {row.consumer_request.additional_information.substring(0, 104)}
                                  <span style={{ color: '#FF7D26' }}>...</span>
                                </span>
                              }
                            >
                              <Box
                                sx={{
                                  width: '500px',
                                  textAlign: 'center',
                                  backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                  pointerEvents: 'auto',
                                  p: 2,
                                }}
                              >
                                {row.consumer_request.additional_information}
                              </Box>
                            </HoverPopper>
                          ) : (
                            row.consumer_request.additional_information
                          )}
                          <ArrowTooltip
                            title={
                              copiedComment === row.consumer_request.additional_information
                                ? 'Copied!'
                                : ''
                            }
                          >
                            <StyledButton
                              onClick={(e: any) =>
                                handleCopyComment(
                                  e,
                                  row.consumer_request.additional_information ?? ''
                                )
                              }
                            >
                              <img
                                src={
                                  copiedComment === row.consumer_request.additional_information
                                    ? CopyFilled
                                    : Copy
                                }
                                alt="Copy icon"
                              />
                            </StyledButton>
                          </ArrowTooltip>
                        </>
                      ) : (
                        'No additional information'
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
