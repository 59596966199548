import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { ServiceRequest } from '../../../../models/service_request'
import { CreateOffer } from '../../../../models/offer'
import { useNavigate } from 'react-router-dom'
import { APP_FONT } from '../../../../constants/app_font'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'
import { createOffer } from '../../../../redux/actions/offer'
import ModalTableHead from './components/table_head'
import FilePreview from './components/file_preview'
import { ServicePrices } from './offer_modal'
import { DiagnosticOnlyOffer } from "./components/diagnostic_only_offer";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflowY: 'auto',
}

interface Props {
  setOpen: (arg: boolean) => void
  open: boolean
  onHide: () => void
  service_request: ServiceRequest
  selectedTimeblock: string | undefined
  setSelectedTimeblock: (arg: any) => void
  alert: boolean
  setAlert: (arg: boolean) => void
}

export interface ServicePrice {
  [serviceId: string]: string
}

export default function DiagnosticModal({
                                          setOpen,
                                          open,
                                          setAlert,
                                          alert,
                                          onHide,
                                          service_request,
                                        }: Props) {
  const dispatch = useDispatch()
  const [alertText, setAlertText] = useState('')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [servicePrice, setServicePrice] = useState<string>()

  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')

  const handleOfferSubmission = () => {
    const providerId = localStorage.getItem('branchId')
    if (providerId) {
      const general_diagnostic = service_request.services.find(
        (service: any) => service.service.type === 'DIAGNOSIS'
      )
      const servicesArray = Object.entries(servicePrices).map(([serviceId, price]: any) => ({
        service_id: serviceId,
        price: parseFloat(price.replace(/,/g, '')),
      }))

      if (general_diagnostic) {
        servicesArray.push({
          service_id: general_diagnostic.service_id,
          price: 0,
        })
      }

      setIsLoading(true)
      const offer: CreateOffer = {
        consumer_request_id: service_request.id,
        provider_branch_id: providerId,
        pick_up_after: service_request.requestedDateTime,
        services: servicesArray,
        ...(servicePrice && { diagnostic_fee: parseFloat(servicePrice.replace(/,/g, '')) }),
        supply_fee: parseFloat(shopSupplyFees?.replace(/[^0-9.-]+/g, '')) || 0,
        tax: parseFloat(tax?.replace(/[^0-9.-]+/g, '')) || 0,
      }

      dispatch<any>(createOffer(offer))
        .then(unwrapResult)
        .then((offer: any) => {
          if (offer) {
            setOpen(false)
            navigate(0)
          }
        })
        .catch((error: any) => {
          setIsLoading(false)
          setAlert(true)
          setAlertText(error)
          console.log('error', error)
        })
    }
  }

  const handleServicePriceChange = (
    serviceId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setServicePrices((prevPrices) => ({
      ...prevPrices,
      [serviceId]: formattedNumber,
    }))
  }

  useEffect(() => {
    const total = calculateSubTotalPrice()
    const formattedTotal = total.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices])

  const calculateSubTotalPrice = (): number => {
    return Object.values(servicePrices).reduce((total, priceStr) => {
      const cleanPriceStr = priceStr.replace(/[^\d.]/g, '')
      const priceNumber = parseFloat(cleanPriceStr)

      if (!isNaN(priceNumber)) {
        const roundedPrice = Math.round(priceNumber * 100) / 100
        return total + roundedPrice
      }
      return total
    }, 0)
  }

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice()

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [fee, tax, shopSupplyFees])

  const allPricesSet = (): boolean => {
    return service_request.services
      .filter((service: any) => service.service.type !== 'DIAGNOSIS')
      .every(
        (service) => servicePrices[service.service_id] && servicePrices[service.service_id] !== ''
      )
  }

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const isOnlyDiagnostic = service_request.services.length === 0 && service_request.type === 'DIAGNOSTIC'

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  return (
    <Modal
      open={open}
      onClose={onHide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      sx={{ overflow: 'hidden' }}
    >
      <Box sx={style}>
        {alert && <Alert severity="error">{alertText}</Alert>}
        <ModalTableHead
          onHide={onHide}
          service_request={{
            slug: service_request.slug,
            vehicle: service_request.vehicle,
            services: service_request.services,
            drop_off_time: service_request.requestedDateTime,
            is_diagnostic: service_request.type === 'DIAGNOSTIC',
          }}
        />
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              mb: 0,
              mt: 3,
            }}
          >
            {service_request.files.length > 0 ? (
              <Stack
                spacing={1}
                sx={{
                  width: '70%',
                  mb: 2,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Photos and video by customer
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                  Uploaded by the customer to explain the issue
                </Typography>
                <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
                  <FilePreview files={service_request.files || []} />
                </Box>
              </Stack>
            ) : (
              ''
            )}
            <Stack
              spacing={1}
              sx={{
                width: '70%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontFamily: APP_FONT,
                  fontWeight: '700',
                  textAlign: 'left',
                  mb: 3,
                }}
              >
                1. Diagnostic Requirements:
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: APP_FONT,
                  fontWeight: '600',
                  marginBottom: 2,
                }}
              >
                Carma Partner Service Centers must complete diagnostics ASAP (maximum of 4 hours
                after drop-off) to receive approval for recommended services from Fleet Customers.
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              marginY={4}
              sx={{
                width: '70%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontFamily: APP_FONT,
                  fontWeight: '700',
                  textAlign: 'left',
                  mb: 3,
                }}
              >
                {'2. Your Offer:'}
              </Typography>
              {isOnlyDiagnostic ? (
                <DiagnosticOnlyOffer />
              ) : null}
              {service_request?.services.map((item: any) => (
                <Box
                  key={item.service_id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                      }}
                    >
                      {item.service.parent.parent_id ? item.service.parent.name : item.service.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.service.parent.parent_id && item.service.name}
                    </Typography>
                  </Stack>
                  <TextField
                    disabled={item.service.type === 'DIAGNOSIS'}
                    value={
                      servicePrices[item.service_id] ||
                      (item.service.type === 'DIAGNOSIS' ? '0.00' : '')
                    }
                    onChange={(event: any) => handleServicePriceChange(item.service_id, event)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      style: { background: '#fff' },
                    }}
                    inputProps={{
                      maxLength: 9,
                      style: { background: '#fff', height: '17px' },
                    }}
                    style={{ width: '30%' }}
                  />
                </Box>
              ))}
              <Divider
                sx={{
                  marginY: '16px !important',
                }}
              />
              {!isOnlyDiagnostic ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontFamily: APP_FONT,
                          fontWeight: '600',
                          color: '#393A3D',
                        }}
                      >
                        Shop supply fees
                      </Typography>
                    </Stack>
                    <TextField
                      value={shopSupplyFees}
                      onChange={handleFeeChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        style: { background: '#fff' },
                      }}
                      inputProps={{
                        maxLength: 9,
                        style: { background: '#fff', height: '17px' },
                      }}
                      style={{ width: '30%' }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontFamily: APP_FONT,
                          fontWeight: '600',
                          color: '#393A3D',
                        }}
                      >
                        Tax
                      </Typography>
                    </Stack>
                    <TextField
                      value={tax}
                      onChange={handleTaxChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        style: { background: '#fff' },
                      }}
                      inputProps={{
                        maxLength: 9,
                        style: { background: '#fff', height: '17px' },
                      }}
                      style={{ width: '30%' }}
                    />
                  </Box>
                  <Divider
                    sx={{
                      marginY: '16px !important',
                    }}
                  />
                </>
              ) : null}
              <Box display="flex" gap={4}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#393A3D',
                    width: '100%',
                  }}
                >
                  Total:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    color: '#DB5D08',
                  }}
                >
                  ${totalFee}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 3,
                  }}
                >
                  <Button
                    // disabled={isLoading || !allPricesSet() || !isPickupDateValid}
                    disabled={isLoading || !allPricesSet()}
                    onClick={handleOfferSubmission}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      borderRadius: 10,
                      marginTop: 3,
                      backgroundColor: '#FF6600',
                      height: 35,
                      width: 250,
                      ':hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: APP_FONT,
                        fontWeight: '700',
                      }}
                    >
                      SUBMIT OFFER
                    </div>
                  </Button>
                </Box>
              </Box>
              {/* <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 3,
                  }}
                >
                  <Button
                    disabled={isLoading}
                    onClick={handleOfferSubmission}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      borderRadius: 10,
                      marginTop: 3,
                      backgroundColor: '#FF6600',
                      height: 35,
                      width: 250,
                      ':hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        fontFamily: APP_FONT,
                        fontWeight: '700',
                      }}
                    >
                      SUBMIT OFFER
                    </div>
                  </Button>
                </Box>
              </Box> */}
            </Stack>
          </Box>
        </Box>
        {isLoading && (
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    </Modal>
  )
}
