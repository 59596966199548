import React from 'react';
import { Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { APP_FONT } from "../../../../../constants/app_font";

export const DiagnosticOnlyOffer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Stack spacing={1}>
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: APP_FONT,
            fontWeight: '600',
            color: '#393A3D',
          }}
        >
          Diagnostic Only
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {/*{item.service.parent.parent_id && item.service.name}*/}
        </Typography>
      </Stack>
      <TextField
        disabled={true}
        value='0.00'
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          style: { background: '#fff' },
        }}
        inputProps={{
          maxLength: 9,
          style: { background: '#fff', height: '17px' },
        }}
        style={{ width: '30%' }}
      />
    </Box>
  );
};
