import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  styled,
  IconButton,
  Alert,
  AlertTitle,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { APP_FONT } from '../../../../constants/app_font'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'
import HoverPopper from '../../../../components/Hover/hover_popper'
import { formatDateTime } from '../../../../utils/helper'
import Copy from '../../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../../assets/images/contentCopyFilled.svg'
import { updateOffer } from '../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { getHolidaysForYear, isSameDay } from '../../../../utils/holidays'
import { Offer } from '../../../../models/offer_new'
import { ArrowTooltip } from './components/ui/arrow_tooltip'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  width: 800,
  overflow: 'visible',
}

interface Props {
  setOpen: (arg: boolean) => void
  open: boolean
  onHide: () => void
  offer: Offer
}

export default function SetUpPickUpModal({ setOpen, open, onHide, offer }: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isValidDate, setIsValidDate] = useState(true)
  const [isDateLessThanDropOff, setIsDateLessThanDropOff] = useState(true)
  const [tempDate, setTempDate] = useState<Date | null>()
  const [alertOpen, setAlertOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [copiedVin, setCopiedVin] = useState<string | null>(null)

  const dropOffTime = new Date(offer.drop_off_by)
  const today = new Date(offer.drop_off_by)
  let minDate = new Date(dropOffTime.getFullYear(), dropOffTime.getMonth(), dropOffTime.getDate())

  let maxDate = new Date(minDate)
  maxDate.setDate(maxDate.getDate() + 1)

  let minTimeToday = new Date()
  minTimeToday.setHours(dropOffTime.getHours(), dropOffTime.getMinutes() + 60, 0)
  const minTimeNextDays = new Date(new Date().setHours(8, 0, 0))

  const maxTime = new Date()
  maxTime.setHours(17, 0, 0)

  const currentYear = new Date().getFullYear()
  const nextYear = currentYear + 1
  const holidaysThisYear = getHolidaysForYear(currentYear)
  const holidaysNextYear = getHolidaysForYear(nextYear)
  const holidays = [...holidaysThisYear, ...holidaysNextYear]

  const StyledButton = styled(IconButton)`
    & {
      width: 35px;
      height: 35px;
      margin: 12px;
    }
    ,
    &&:hover {
      width: 35px;
      height: 35px;
      background-color: #fff0eb;
      margin: 12px;
    }
  `

  const handleSubmission = () => {
    setLoading(true)
    if (tempDate) {
      const updatedOffer: any = {
        id: offer.id,
        pick_up_after: tempDate,
      }

      dispatch<any>(updateOffer(updatedOffer))
        .then(unwrapResult)
        .then((offer: any) => {
          if (offer) {
            setOpen(false)
            navigate(0)
          }
        })
        .catch((error: any) => {
          setLoading(false)
          console.log('error')
        })
    }
  }

  const isDateDisabled = (date: Date) => {
    return holidays.some((holiday) => isSameDay(date, holiday))
  }

  const handleTempDate = (date: Date) => {
    const isHoliday = isDateDisabled(date)
    setAlertOpen(isHoliday)
    setTempDate(date)
  }

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => setCopiedVin(null), 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  useEffect(() => {
    setTempDate(dropOffTime)
  }, [])

  return (
    <Modal
      open={open}
      onClose={onHide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      sx={{ overflow: 'hidden' }}
    >
      <Box sx={style}>
        <TableContainer>
          <Table size="medium">
            <TableHead sx={{ backgroundColor: '#FF6600' }}>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: '700',
                    fontFamily: APP_FONT,
                    fontSize: 14,
                    color: 'white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  ID #
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: '700',
                    fontFamily: APP_FONT,
                    fontSize: 14,
                    color: 'white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Vehicle
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: '700',
                    fontFamily: APP_FONT,
                    fontSize: 14,
                    color: 'white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  VIN
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: '700',
                    fontFamily: APP_FONT,
                    fontSize: 14,
                    color: 'white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Service
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: '700',
                    fontFamily: APP_FONT,
                    fontSize: 14,
                    color: 'white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Drop-off time
                </TableCell>
                <CloseIcon
                  onClick={onHide}
                  style={{
                    cursor: 'pointer',
                    height: 17,
                    width: 17,
                    position: 'absolute',
                    right: '0px',
                  }}
                />
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT, width: 70 }}>
                  {offer.slug ?? offer.id}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
                  {offer.vehicle?.year} {offer.vehicle?.make} {offer.vehicle?.model}{' '}
                  {offer.vehicle?.trim ?? ''}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                    {offer.vehicle?.vin || 'VIN not provided'}
                    {offer.vehicle?.vin && (
                      <ArrowTooltip title={copiedVin === offer.vehicle?.vin ? 'Copied!' : ''}>
                        <StyledButton
                          onClick={(event: any) => handleCopyVin(event, offer.vehicle?.vin ?? '')}
                        >
                          <img src={copiedVin === offer.vehicle?.vin ? CopyFilled : Copy} alt="" />
                        </StyledButton>
                      </ArrowTooltip>
                    )}
                  </div>
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
                  {!!offer?.services?.length && (
                    <HoverPopper holder={`(${offer?.services?.length}) see all`}>
                      <Box
                        sx={{
                          backgroundColor: 'rgba(238, 238, 238, 0.95)',
                          pointerEvents: 'auto',
                          paddingTop: 3,
                          paddingLeft: 3,
                          paddingRight: 3,
                          paddingBottom: 1,
                        }}
                      >
                        {offer?.services?.map((item: any) => {
                          let displayName = item?.service.name

                          if (item?.service.parent?.name) {
                            displayName = `${item.service.parent.name}  
                              (${displayName}${
                              item.service.type === 'TIRE' &&
                              offer.vehicle.tires &&
                              offer.vehicle.tires.length > 0
                                ? `, ${offer.vehicle.tires[0].width}/${offer.vehicle.tires[0].ratio}${offer.vehicle.tires[0].construction}${offer.vehicle.tires[0].diameter}`
                                : ''
                            })`
                          }
                          return (
                            <Typography
                              key={`a-${item.service.name}`}
                              mb={1.5}
                              fontFamily={APP_FONT}
                              fontSize={'14px'}
                            >
                              {displayName}{' '}
                            </Typography>
                          )
                        })}
                      </Box>
                    </HoverPopper>
                  )}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT, width: 200 }}>
                  <Box>{offer.drop_off_by && formatDateTime(offer.drop_off_by)}</Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box>
          <Stack spacing={1} marginY={4}>
            <Stack>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontFamily: APP_FONT,
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                Set exact pick-up time
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: APP_FONT,
                  fontWeight: '400',
                  textAlign: 'center',
                  mb: 3,
                }}
              >
                Estimated pick-up time: {formatDateTime(offer.pick_up_after)}
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              {alertOpen && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    pt: 3,
                  }}
                >
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setAlertOpen(false)
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    severity="warning"
                    sx={{
                      borderRadius: '12px',
                      borderColor: '#FEC84B',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }}
                  >
                    <AlertTitle>
                      <strong>The date you've selected for pick-up is a holiday.</strong>
                    </AlertTitle>
                    Ensure that your service team is available on this day for timely completion.
                  </Alert>
                </Box>
              )}
              <Box>
                <DatePicker
                  selected={tempDate}
                  onChange={handleTempDate}
                  showTimeSelect
                  timeFormat="p"
                  timeIntervals={15}
                  dateFormat="Pp"
                  placeholderText="mm/dd/yyyy --:-- --"
                  className="my-datepicker-input"
                  minDate={minDate}
                  maxDate={maxDate}
                  minTime={tempDate && isSameDay(tempDate, today) ? minTimeToday : minTimeNextDays}
                  maxTime={maxTime}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontFamily: APP_FONT,
                    fontWeight: '400',
                    color: '#393A3D',
                    width: 280,
                    ml: 3,
                    mt: 1,
                  }}
                >
                  When will the car be ready for pick up?
                </Typography>
                <Button
                  disabled={!isValidDate || !isDateLessThanDropOff}
                  onClick={handleSubmission}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    borderRadius: 10,
                    marginTop: 3,
                    marginLeft: '10px',
                    backgroundColor: '#FF6600',
                    height: 35,
                    width: 280,
                    ':hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: APP_FONT,
                      fontWeight: '700',
                    }}
                  >
                    SAVE
                  </div>
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
        {isLoading && (
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    </Modal>
  )
}
